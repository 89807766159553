body {
  font-family: Rubik, sans-serif;
  font-weight: 300;
}

h1 {
  font-weight: 800 !important;
}

/*# sourceMappingURL=index.8a75cfaa.css.map */
