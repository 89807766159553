body {
  font-family: 'Rubik', sans-serif;
  font-weight: 300;
  /* background-color: #121212;
  color: rgba(255, 255, 255, 0.87); */
}

h1 {
  font-weight: 800 !important;
}
